/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import Layout from '@lekoarts/gatsby-theme-cara/src/components/layout';
import MenuBar from '../@lekoarts/gatsby-theme-cara/components/menu-bar';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../@lekoarts/gatsby-theme-cara/constants/theme.js';
import SEO from '@lekoarts/gatsby-theme-cara/src/components/seo';
import BlogBackground from '../@lekoarts/gatsby-theme-cara/components/blog-background';
import GenericFooter from '../@lekoarts/gatsby-theme-cara/components/generic-footer';
import { graphql } from 'gatsby';
import ContactForm from '../@lekoarts/gatsby-theme-cara/components/contact-form';

const Custom404Page = ({ data }) => {
  return (
    <ThemeProvider theme={theme}>
      <SEO title="404: Not Found" />
      <Layout className="Blog-post-page">
        <MenuBar />
        <h1 style={{ textAlign: 'center', margin: '200px auto', fontSize: '60px' }}>
          Page not found.
        </h1>
        <div>
          <ContactForm />
          <GenericFooter />
        </div>
      </Layout>
    </ThemeProvider>
  );
};

export default Custom404Page;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "Coffee.png" }) {
      childImageSharp {
        fixed(height: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
